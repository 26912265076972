.wrapper {
    /* width: 100%;
    height: 100%; */
    /* position: absolute; */
    background: linear-gradient(300deg, deepskyblue, darkviolet, blue);
    background-size: 180% 180%;
    animation: gradient-animation 4s ease infinite;
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}